<!--
 * @Description: 开票详情
 * @Author: ChenXueLin
 * @Date: 2021-09-26 09:35:56
 * @LastEditTime: 2021-10-09 15:25:24
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
    >
      <!-- 表格 start -->
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 头部状态 -->
          <detail-title-content :btnType="1" btnText="任务单状态:进行中">
            <template v-slot:rightButtons>
              <el-button type="primary">发票预览</el-button>
            </template>
          </detail-title-content>
          <template>
            <!-- 基本信息 start -->
            <div class="baseInfo-box">
              <el-form
                label-width="140px"
                label-position="right"
                class="formBox"
              >
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="开票编号">
                      1234567
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="申请日期">
                      2021-002=10
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="备注"> 张三 </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="联系人"> 张三 </el-form-item>
                  </el-col>

                  <el-col :span="8">
                    <el-form-item label="税号">
                      发货计费
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="单位地址"> 张三 </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="电话号码"> 张三 </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="开户银行"> 张三 </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="银行账户"> 张三 </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="开票类型"> 张三 </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <!-- 基本信息 end -->
            <div class="edit-content">
              <el-table
                border
                height="300px"
                :data="tableData"
                highlight-current-row
                class="elTable"
              >
                <el-table-column
                  show-overflow-tooltip
                  v-for="(column, index) in columnData"
                  :key="index"
                  :prop="column.fieldName"
                  :label="column.fieldLabel"
                  :min-width="column.width"
                  :fixed="column.fixed"
                  :align="column.align"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  fixed="right"
                  align="center"
                  label="操作"
                  width="60"
                >
                  <template slot-scope="scope">
                    <e6-td-operate
                      :data="getOperateList()"
                      @command="handleOperate($event, scope.row)"
                    ></e6-td-operate>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
// import { exportXlsx } from "@/utils/util";
import detailTitleContent from "@/components/detailTitleContent";

export default {
  name: "projectDetail",
  data() {
    return {
      activeName: "second",
      columnData: [
        {
          fieldName: "userId",
          display: true,
          fieldLabel: "账单编号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userName",
          display: true,
          fieldLabel: "账单生成日期",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "phone",
          display: true,
          fieldLabel: "账单类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "userType",
          display: true,
          fieldLabel: "账单状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "role",
          display: true,
          fieldLabel: "应收金额",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "company",
          display: true,
          fieldLabel: "减免金额",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "department",
          display: true,
          fieldLabel: "结算金额",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "job",
          display: true,
          fieldLabel: "币种",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: []
    };
  },
  mixins: [base, listPage, listPageReszie],
  components: {
    detailTitleContent
  },
  created() {},
  mounted() {},

  methods: {
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1
        }
      ];
      return operates;
      // return this.filterOperateBtn(operates);
    },
    // 操作--更多
    handleOperate(val) {
      // console.log(val, row);
      if (val.id == 1) {
        this.routerPush({
          name: "invoiceManage/invoiceDetail",
          params: {
            refresh: true
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-content {
  padding: 20px 20px 2px;
}
</style>
